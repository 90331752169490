import React, { useEffect, useMemo, useState } from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import Layout from '../../components/shared/layout';
import SEO from '../../components/SEO/SEO';
import {
  color, breakpoints, sizing, title, tabs, button, hideScroll, border, flex,
} from '../../utils/style';
import Breadcrumbs from '../../components/shared/Breadcrumbs';
import TopTen from '../../components/shared/TopTen';
import { getFile } from '../../utils/system';
import CookieDisableComponent from '../../components/shared/CookieDisableError';
import lotteryLinkArrow from '../../img/lottery-link-arrow.svg';

const Wrapper = styled('div')`
  p {
    margin-bottom: 1rem;
  }
`;

const FeaturedWrapper = styled('div')`
  position: relative;
  margin: 15px 0 0;
  width: 100%;
  ${title}
  .breadcrumbs {
    position: absolute;
    z-index: 1;
    top: 20px;
    left: 30px;
  }
  .gatsby-image-wrapper {
    border-radius: ${border.borderRadius} ${border.borderRadius} 0 0;
    img {
      width: 100%;
    }

    @media (max-width: ${breakpoints.md}) {
      border-radius: 4px;
    }
  }

  @media (max-width: ${breakpoints.xs}) {
    margin-bottom: 15px;
    .breadcrumbs {
      position: static;
      margin: 10px 0;
    }
    .gatsby-image-wrapper {
      height: 100px;
    }
  }
`;

const Content = styled('div')`
  position: static;
  padding: 40px 50px 30px 10px;

  a {
    ${button};
    margin: 30px 0;
    width: 233px;
    height: 59px;
    border-radius: 8px;
    background-color: #00c500;
    ${flex};

    &:hover {
      background-color: #009500;
    }

    &:active {
      background-color: #009000;
      opacity: 0.7;
    }
  }

  @media (max-width: ${breakpoints.md}) {
    padding: 0;
  }
`;

const ContentWrapper = styled('div')`
  display: flex;
  position: relative;
  border-radius: 0 0 8px 8px;
  background-color: #141d44;
  padding: 24px 40px 5px;
  margin-top: calc(65px + 30px);

  .lottery-link {
    width: 100%;
    height: 85px;
    padding: 6px 15px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 20px;
    background: rgb(228, 245, 252);
    background: linear-gradient(180deg, rgba(228,245,252,1) 0%, rgba(197,245,248,1) 100%);

    img {
      height: auto;
      width: 64px;
      margin-right: 20px;
      border-radius: 50%;
    }

    > div {
      text-align: left;

      > h4 {
        margin: 0;
        color: ${color.darkGrey};
      }

      > span {
        position: relative;
        color: #28B7FA;

        &:after {
          content: "";
          position: absolute;
          width: 7px;
          height: 13px;
          top: 6px;
          right: -20px;
          background-image: url(${lotteryLinkArrow});
          background-size: cover;
          background-repeat: no-repeat;          
        }
      }
    }
  }

  @media (max-width: ${breakpoints.md}) {
    padding: 20px;
    margin-top: calc(44px + 20px);

    & .wrapper-tabs {
      top: -54px !important;
      & .tab-menu {
        margin-bottom: 0;
        & > li {
          padding: 0 0 0 0 !important;
          & > a {
            height: 44px !important;
            font-family: Kanit;
            font-size: 14px !important;
            font-weight: normal !important;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
          }
        }
      }
    }
  }
  h3 {
    margin-top: 0;
  }
  ul {
    list-style: none;
    padding: 0;
  }
  li {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 0;
  }
  li:nth-of-type(odd) {
    padding-right: 20px;
  }
  li:nth-of-type(even) {
    padding-left: 20px;
  }
  .recent-excerpt div {
    margin: 10px 0;
    font-size: ${sizing.small};
  }
  .wrapper-tabs {
    position: absolute;
    left: 0;
    top: -65px;
    width: 100%;
    overflow: hidden;
    height: max-content;

    .tab-menu {
      ${tabs};
      ${hideScroll};
      overflow-x: scroll;
      position: relative;
      animation-duration: 1s;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      & > li {
        margin-bottom: 0;
        border-radius: 0;
        width: 100%;

        &:last-child {
          margin-right: 0;
        }

        & > a {
          border-radius: 4px 4px 0 0;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 65px;

          &.active {
            background-color: #141d44;
          }
        }
      }
    }
  }
  @media (max-width: ${breakpoints.md}) {
    .wrapper-tabs {
      height: 75px;
      top: -60px;
    }
    h3 {
      font-size: 1.2rem;
    }
    ul {
      margin: 10px 0;
    }
    li {
      width: 100%;
      padding: 10px 0 !important;
    }
  }

  @media (max-width: ${breakpoints.xs}) {
    .wrapper-tabs {
      height: 75px;
      top: -63px;
      .tab-menu {
        animation-name: movetabs;
      }
    }
    .recent-excerpt div,
    .recent-excerpt p {
      display: inline;
    }
    .recent-excerpt .button {
      padding: 0;
      background: transparent;
      display: inline;
      color: ${color.blue};
      font-weight: ${sizing.normalWeight};
      font-size: inherit;
      text-decoration: underline;
      &:active,
      &:focus,
      &:hover {
        color: ${color.lightGreen};
        background: none;
      }
    }
  }
`;

const Sidebar = styled('div')`
  width: 268px;
  @media (max-width: ${breakpoints.md}) {
    display: none;
  }
`;

const PageWithTopTenComponent = ({ data, pageContext, img, imgName }) => {
  const { wordpressPage, allWordpressWpApiMenusMenusItems } = data;
  const { featured_media = {}, yoast_meta, title, content, path } = wordpressPage;
  const { nodes = [{}] } = allWordpressWpApiMenusMenusItems;
  const { items: menu = [] } = nodes[0];
  const { yoast_wpseo_title, yoast_wpseo_metadesc } = yoast_meta;
  const { related, breadcrumbs } = pageContext;
  const metaTitle = (yoast_meta && yoast_wpseo_title) || `${title}- ${process.env.SITE_NAME_SHORT}`;
  const metaDesc = (yoast_meta && yoast_wpseo_metadesc) || '';
  const [localError, setLocalError] = useState(false);

  useEffect(() => {
    try {
      if (window.localStorage) {
        setLocalError(false);
      }
    } catch (error) {
      setLocalError(true);
    }
  });

  const Result = useMemo(
    () => (
      <Layout>
        <SEO title={metaTitle} description={metaDesc} pathname={path} breadcrumbs={breadcrumbs} />
        <Wrapper>
          <FeaturedWrapper>
            <Breadcrumbs breadcrumbs={breadcrumbs} page_title={title} />
            {getFile(featured_media) && (
              <Img
                fluid={getFile(featured_media)}
                alt={featured_media.alt_text || title}
                fadeIn={false}
                loading="eager"
              />
            )}
            <h1>
              {title}
              {img && <img className="iconImage" src={img} alt={imgName} />}
            </h1>
          </FeaturedWrapper>
          {related.length && (
            <ContentWrapper>
              <div className="wrapper-tabs">
                <ul className="tab-menu" id="menu">
                  {menu?.map(({ type_label, object_slug, path, title }) => (
                    <li key={type_label + object_slug}>
                      <Link activeClassName="active" to={`/${path}`}>
                        {title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <Content>
                <div dangerouslySetInnerHTML={{ __html: content }} />
              </Content>
              <Sidebar>
                <TopTen />
              </Sidebar>
            </ContentWrapper>
          )}
        </Wrapper>
      </Layout>
    ),
    [img, title],
  );

  if (!localError) {
    return Result;
  }

  return (<CookieDisableComponent />);
};

export default PageWithTopTenComponent;

export const acf = graphql`
  fragment acf on wordpress__PAGEAcf {
    page_template
  }
`;
